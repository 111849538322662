@import 'colors';

.navbar{
    padding-left: calc(var(--bs-gutter-x)* 0.5);
    background-color: $border-inverse !important;
}

.custom-navbar{
    padding-left: calc(var(--bs-gutter-x)* 0.5);
    background-color: $border-inverse !important;
}

.navbar-toggler{
    color: $border-inverse;
}

.navbar-offcanvas {
    background-color: $border-inverse;;
}

.custom-toggler .navbar-toggler-icon{
    width: 1.2em;
    height: 1.2em;
}

.navbar_brand_text{
    color: $text-on-color;
}

.navbar_text{
    color: $text-on-color;
}

.navbar_text:hover {
    color: #ffffff;
    font-weight: bold; /* Change this to your desired hover color */
    
}

.navbar-nav .nav-link.hover, .navbar-nav .nav-link.show {
    color: #ffffff;
    border-bottom: 2px solid $focus; /* Add border to the active link */
    font-weight: bold;
    // border-radius: 5px; /* Optional: add border radius */
    // padding: 5px; /* Optional: add paddi
}   

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #ffffff;
    border-bottom: 2px solid $focus; /* Add border to the active link */
    font-weight: bold;
    // border-radius: 5px; /* Optional: add border radius */
    // padding: 5px; /* Optional: add paddi
}   

.navbar__inner {
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // width: 100%;
}
.navbar__items {
    align-items: center;
    display: flex;
    flex: 1;
    min-width: 0;
}

.navbar__toggle{
    margin-right: .5rem;
}