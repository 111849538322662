@use "ag-grid-community/styles" as ag;
@use "colors" as *;
@use "variables" as *;
@include ag.grid-styles();

@import 'tableStyle';

@import "./../../node_modules/bootstrap/scss/bootstrap";
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons.scss";

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import 'navbar';

.about_heading{
    font-size: 1.5rem;
}

.ag-theme-quartz {
    height: 75vh;
}

.blue-link{
    color:#006cbe
} 

.button{
    --bs-btn-border-radius:0;
}

.btn{
    height: $button-height;
}

.button-table{
    background-color: $background;
    border-color: $background;
    color: $text-helper;
}

.button-header{
    background-color: $text-helper;
    border-color: $text-helper;
}

.button_end_align{
    align-content: flex-end;
}

.btn-outline {
    border-color: #005A9E;
}

.btn-width{
    width: 6.5em;;
}

.content_container{
    padding-right: calc(var(--bs-gutter-x)* 2.5);
    padding-left: calc(var(--bs-gutter-x)* 2.5);
}

.custom-width-icon{
    transform: scaleX(1.5);
}

.file-table-theme {
    height: 40vh;
}

.large-icon{
    font-size: 1.5rem;
}

long-icon-button{
    display: block;
}

.root_container{
    padding-right: 0;
    padding-left: 0;
}

.batch-accordion-card{
    --bs-card-border-color:rgb(255, 255, 255);
}

.action-stack{
    justify-content: end;
}

.btn:disabled{
    background-color: #d3d3d3; /* Light gray background */
    color: #a9a9a9; /* Dark gray text */
    border-color: #d3d3d3;
    pointer-events: none; /* Disable pointer events */
    opacity: 0.65; /* Slightly transparent */
}
.disabled-button .btn .btn-primary{
    background-color: #d3d3d3; /* Light gray background */
    color: #a9a9a9; /* Dark gray text */
    border-color: #d3d3d3;
    pointer-events: none; /* Disable pointer events */
    opacity: 0.65; /* Slightly transparent */
}

.ag-popup 
{
  height: 0 !important;
}

.small-text
{
    font-size: 0.8rem;
    text-align: end;
}

.center-text {
    text-align: center;
}

.footer_container{
    padding: 1rem 0;
}

.accordion-button{
    
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
    color: #000000;
}

.dropzone {
    text-align: center;
    padding: 20px;
    border: 1px $button-primary dashed;
    width: 100%;
    margin: auto;
    margin-bottom: 1%;
}

.right-button-container{
    display: flex;
    justify-content: flex-end;
}

.bottom-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    position: relative;
}

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.state-filter {
    display: flex;
    align-items: center;
    margin: 1em 0 1em 1em;
}