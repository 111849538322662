@use "variables" as *;

.table-align{
    padding-top: $button-height;
}
.half-page-table{
    height: 34rem;
}

.job-description-table{
    background-color: inherit;
    border-style: none;
    tr {
        background-color: inherit;
        border-style: none;
        td{
            background-color: inherit;
            border-style: none;
        }
    }
}